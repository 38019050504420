.block{
    /* 
        border: 3pt solid red; */
        padding: 2%;
    }
    
    .content{
        margin-top: -17%;
    }
    
    .Icontainer{
        padding: 5%;
        margin-top: -23%;
        display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: minmax(0px, auto); /* Adjust the minimum and maximum row height as needed */
  gap: 10px; /* Adjust the gap between grid items */
    }
    .Icard{
        transition: transform 0.6s ease;
         /* Added ease for background-color */
            /* border: 3pt solid red; */
         height: auto;
         width: auto;


         /* border-radius: 250px 750px 250px 750px /
                     750px 250px 750px 250px;
      animation: wobble 9s ease-in-out alternate infinite; */
    }

    .Icard img{
        height: auto;
        width: auto;

        /* border: 3pt solid black; */
    }
    
    /* @keyframes wobble {
        0% {
          border-radius: 250px 750px 250px 750px / 750px 250px 750px 250px;
        }
        25% {
          border-radius: 500px 650px 300px 700px / 400px 600px 500px 300px;
        }
        50% {
          border-radius: 750px 550px 350px 750px / 350px 750px 550px 450px;
        }
        
        100% {
          border-radius: 750px 250px 750px 250px / 250px 750px 250px 750px;
        }
      }
       */
    
    .Icard:hover {
        transform: scale(1.06);
        
      }
      
      .bubblegum-sans-regular{
        font-size: xx-large;
      }