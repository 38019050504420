.kashmir{

    height: 600px;
    padding: 2%;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    
}


@media only screen and (max-width: 1024px) {
    .kashmir {

        background-attachment: scroll;
        height: auto;
    width: auto;
    background-repeat: repeat-y;
    }
}

/* For mobile devices (landscape and portrait) */
@media only screen and (max-width: 768px) {
    .kashmir {
        background-attachment: scroll;
        height: auto;
    width: 100vw;
    background-repeat: repeat-y;
    }
}