
  /* w-full md:w-4/5 lg:w-3/4 xl:w-5/6 h-96 */
  .map-box{
    
    height: 570px;
    
    width: 80%;
    
    margin-top: 2rem;
    /* border: 3pt solid black; */
    
}


@media (max-width: 661px) {
  .map-box {
      margin-left: 5%;
      height: 500px;
      /* border: 3pt solid red; */
      padding: 3%;
  }

  .map-box .main-map{
    width: 220%;
    height: 100%;
  }
}
/*  
  w-full lg:w-3/4 px-4 lg:p-7 lg:h-96 rounded-lg shadow-2xl bg-white rounded-lg shadow-2xl bg-white */

  /* .main-box{
    border: 3pt solid black;
    width: 100%;
    
    margin-top: 5%;
  }  */