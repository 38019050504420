.landing {
  display: flex;
}

.left {
  padding: 10%;
  background-color: rgb(47, 134, 108);
  height: auto;
  transition: width 2s, background-color 2s, padding 2s, height 2s;
  
}

.left:hover {
  width: auto; /* Take up the entire screen */
  background-color: goldenrod;
  padding-left: 37%; /* Remove padding */
  height: auto;
}


.left img {
  height: auto;
  width: auto;
  margin-left: 3%;
  margin-top: 9%;
  max-width: 100%;
  max-height: 100%; /* Ensure image doesn't exceed the left block's boundaries */
  transition: transform 2s; /* Added transition property for zoom effect */
}

.left:hover img {
  transform: scale(1.4); /* Zoom effect on hover */
  margin-left: 17%;
}

.right {
  background-color: rgb(230, 88, 45);
  transition: width 2s, background-color 2s, padding 2s;
  padding: 10%;
}

.right:hover {
  width: auto; /* Take up the entire screen */
  height: auto;
  padding-right: 37%;
  background-color: rgb(240, 167, 93);
}


.right img {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%; /* Ensure image doesn't exceed the left block's boundaries */
  transition: margin-left 2s;
}

.right img:hover {
  margin-left: 100%;
}



