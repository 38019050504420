

.connect-container{

    /* margin-bottom: -5%; */
    padding: 23px 0;

    background-image: url('../../assets/contactBg.jpg');
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 1024px) {
    .connect-container {

        background-attachment: scroll;
        height: auto;
    width: 100vw;
    background-repeat: repeat-y;
    }
}

/* For mobile devices (landscape and portrait) */
@media only screen and (max-width: 768px) {
    .connect-container {
        background-attachment: scroll;
        height: auto;
    width: auto;
    }
}