.home2half {
    background-image: linear-gradient(to right, rgb(8, 160, 231), rgb(16, 238, 164));
 
  }
  

.aboutContent{
    padding: 2%;
    
}








.paraText{
    font-size: medium;
    
    
    color: rgb(87, 84, 87);

    
    
}