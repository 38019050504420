.about{
    background-image: url('../../assets/aboutBg.png');
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: auto;
    padding: 33px 0;
}

@media only screen and (max-width: 1024px) {
    .about {

        background-attachment: scroll;
        height: auto;
    width: 100vw;
    background-repeat: repeat-y;
    }
}

/* For mobile devices (landscape and portrait) */
@media only screen and (max-width: 768px) {
    .about {
        background-attachment: scroll;
        height: auto;
    width: 100vw;
    background-repeat: repeat-y;
    }
}

.eventImg{

    
    box-shadow: 4px 6px 10px rgba(10, 10, 10, 0.5);
    background-image: url("../../assets/g20.jpg");
    background-size: cover;
    height: 300px;

}
