.WhyUs{
     

    background-image: url("../../assets/home3.jpg");

    background-size: cover;
    height: auto;
    width: auto;
    background-position: top;
    padding: 33px 0;
     /* Adjust the blur level as needed */
}

@media only screen and (max-width: 1024px) {
    .WhyUs {

        background-attachment: scroll;
        height: auto;
    width: 100vw;
    background-repeat: repeat-y;
    }
}

/* For mobile devices (landscape and portrait) */
@media only screen and (max-width: 768px) {
    .WhyUs {
        background-image: url("../../assets/lagoon.jpg");
        background-attachment: scroll;
        background-size: cover;
        height: auto;
    width: 100vw;
    background-repeat: repeat-y;
    }
}