
.bg-cover{

    background-image: url('../../assets/homeBg.jpg');
    height: auto;
    width: 100%;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    padding: 33px 0;
}

@media only screen and (max-width: 1024px) {
    .bg-cover {

        background-attachment: scroll;
        height: auto;
    width: auto;
    background-repeat: repeat-y;
    }
}

/* For mobile devices (landscape and portrait) */
@media only screen and (max-width: 768px) {
    .bg-cover {
        background-attachment: scroll;
        height: auto;
    width: 100vw;
    background-repeat: repeat-y;
    }
}



.navbar-container {
    display: flex;
    align-items: center;
    height: 20%;
    width: 100%;
    margin-right: 20px; 
    position: absolute;
    
}

.container{
    /* border: 3pt solid red; */
    margin-top: -7%;
}

.navbar-links {
    display: flex;
    justify-content: flex-end; /* Align links to the end (right side) */
    align-items: center;
    margin-left: 60%;
}

.navbar-link {
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
    margin-left: 20px;
    font-family: "Gill Sans", sans-serif;
    transition: transform 0.3s ease; /* Added transition property */
    /* border: 3pt solid black; */
    padding: 3px;
}

.navbar-link:hover {
    transform: scale(1.6); /* Enlarge the link on hover */
    color: rgb(15, 62, 133);
}

/* .navbar-link:hover {
    transform: scale(1.75);
    color: rgb(48, 104, 187);
} */

.logo img{
    width: 10%;
    height: 10%;
    margin-left: -5%;
}
  

.navBlock {
    width: 100%;
    height: auto; /* Adjust height to auto for responsiveness */
    min-height: 120vh; /* Ensure minimum height of 100vh */
    display: flex;
   
    background-size: cover;
    background-position: center;
    /* animation: slideshow 20s infinite;  */
   
    min-height: 100vh; /* Ensure minimum height of 100vh */
    
    right: 0;
   


}


/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .navBlock {
        padding: 10px;;/* Adjust padding for smaller devices */
    }
}

.titleText {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .10em solid orange; /* The typewriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    letter-spacing: .15em; /* Adjust as needed */
    text-align: left; /* Align text to the left */
    animation: 
      typing-desktop 7.5s steps(40, end) forwards,
      blink-caret .75s step-end 7.5s infinite;
  }
  
  @keyframes typing-desktop {
    from { width: 0 }
    to { width: 52% }
  }
  
  /* Media query for mobile and tablet devices */
  @media (max-width: 768px) {
    .titleText {
      white-space: normal; /* Allow text to wrap */
      width: 100%; /* Set width to 100% for text to fill the container */
      animation: typing-mobile 7.5s steps(40, end) forwards,
      blink-caret .75s step-end 7.5s infinite;
      border-right: .10em solid orange;

    }
    
    @keyframes typing-mobile {
      from { width: 0 }
      to { width: 100% }
    }
    @keyframes blink-caret {
        from, to { border-color: transparent }
      }
  }
    
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
  }
  
  