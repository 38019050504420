.gallery{
    background-image: url('../../assets/galleryBg2.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    width: 100%;
    height: 80vh;
    padding: 33px 0;

}


@media only screen and (max-width: 1024px) {
    .gallery {

        background-attachment: scroll;
        height: auto;
    width: 100vw;
    background-repeat: repeat-y;
    }
}

/* For mobile devices (landscape and portrait) */
@media only screen and (max-width: 768px) {
    .gallery {
        background-attachment: scroll;
        height: auto;
    width: 100vw;
    background-repeat: repeat-y;
    }
}